import type { ComponentRef, EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

export const getWidgetProps = async (editorSDK: EditorSDK | null, componentRef: ComponentRef) => {
  const widgetProps = await editorSDK?.document.application.appStudioWidgets.props.get('', {
    widgetRef: componentRef,
  });
  return widgetProps;
};

export const setWidgetProps = async (
  editorSDK: EditorSDK | null,
  componentRef: ComponentRef,
  newProps: WidgetProps
) => {
  await editorSDK?.document.application.appStudioWidgets.props.set('', {
    widgetRef: componentRef,
    newProps,
  });
};

export const isMobileViewport = async (editorSDK: EditorSDK) => {
  const editorMode = await editorSDK.info.getCurrentViewport();
  return editorMode.type === 'MOBILE';
};

export const getIsStudio = (context: EditorReadyOptions) => {
  return context.origin?.subType === 'STUDIO';
};

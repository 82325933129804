import type { SetDesignVariablesOptions } from '@wix/platform-editor-sdk';
import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { Preset } from 'root/types';
import { DEFAULT_DESIGN_VARS_BY_PRESET, WIDGETS, isEqualDesignVariables } from 'root/utils/defaultDesignVars';

const TOKEN = '';

type DesignVars = SetDesignVariablesOptions['newValues'];

const resetDesignVariablesPromise = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  newValues: DesignVars
) => {
  await editorSDK.application.appStudioWidgets.designVariables.set(TOKEN, {
    widgetRef,
    newValues,
  });
};

export const resetWidgetsDesignVariables = async (
  editorSDK: FlowEditorSDK,
  menuComponentRef: ComponentRef,
  preset: Preset
) => {
  const DEFAULT_DESIGN_VARS = DEFAULT_DESIGN_VARS_BY_PRESET[preset];
  const childrenRefs = await editorSDK.components.getChildren(TOKEN, {
    componentRef: menuComponentRef,
    recursive: true,
  });

  const promises: Promise<void>[] = [];

  for (const widgetRef of childrenRefs) {
    const currentCompDesignVars = await editorSDK.application.appStudioWidgets.designVariables.get(TOKEN, {
      widgetRef,
    });

    if (Object.keys(currentCompDesignVars).length) {
      const currentVar = Object.keys(currentCompDesignVars)[0];

      if (currentVar.includes(WIDGETS.MENU)) {
        const shouldReset = !isEqualDesignVariables(
          DEFAULT_DESIGN_VARS[WIDGETS.MENU],
          currentCompDesignVars as DesignVariables
        );
        if (shouldReset) {
          promises.push(
            resetDesignVariablesPromise(editorSDK, widgetRef, DEFAULT_DESIGN_VARS[WIDGETS.MENU] as DesignVars)
          );
        }
      }
      if (currentVar.includes(WIDGETS.COLUMNS)) {
        const shouldReset = !isEqualDesignVariables(
          DEFAULT_DESIGN_VARS[WIDGETS.COLUMNS],
          currentCompDesignVars as DesignVariables
        );
        if (shouldReset) {
          promises.push(
            resetDesignVariablesPromise(editorSDK, widgetRef, DEFAULT_DESIGN_VARS[WIDGETS.COLUMNS] as DesignVars)
          );
        }
      }
      if (currentVar.includes(WIDGETS.SECTIONS)) {
        const shouldReset = !isEqualDesignVariables(
          DEFAULT_DESIGN_VARS[WIDGETS.SECTIONS],
          currentCompDesignVars as DesignVariables
        );
        if (shouldReset) {
          promises.push(
            resetDesignVariablesPromise(editorSDK, widgetRef, DEFAULT_DESIGN_VARS[WIDGETS.SECTIONS] as DesignVars)
          );
        }
      }
      if (currentVar.includes(WIDGETS.ITEMS)) {
        const shouldReset = !isEqualDesignVariables(
          DEFAULT_DESIGN_VARS[WIDGETS.ITEMS],
          currentCompDesignVars as DesignVariables
        );
        if (shouldReset) {
          promises.push(
            resetDesignVariablesPromise(editorSDK, widgetRef, DEFAULT_DESIGN_VARS[WIDGETS.ITEMS] as DesignVars)
          );
        }
      }
    }
  }

  await Promise.all(promises);
};

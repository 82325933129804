import type { WidgetDesignTabsBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetDesign/widgetDesignTabsBuilder';
import type { WriteOnlyBuilder } from '@wix/app-manifest-builder/dist/types/types';
import type { WidgetDesignTabsArray } from 'root/types';
import type { TFunction, WidgetBuilder, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { AppManifest, GfppConfig, GfppDesktopConfig, GfppMobileConfig } from '@wix/platform-editor-sdk';
import { MENUS_CONTROLLER_WIDGET_ID, MENU_PRESETS_PANEL_ARTICLE_ID } from './consts';
import { getMenuWidgetPresetsMobile, getMenuWidgetPresets } from './widgetPresets';

export const getRole = (componentId: string) => {
  return componentId.substring(1);
};

const addTab = (
  widgetDesignTabsBuilder: WriteOnlyBuilder<WidgetDesignTabsBuilder>,
  label: string,
  roles: string[],
  dependents?: string[]
) => {
  widgetDesignTabsBuilder.addTab((tabBuilder) => {
    tabBuilder
      .set({
        label,
        ...(dependents ? { dependents: dependents.map(getRole) } : {}),
      })
      .groups()
      .set({ roles: roles.map(getRole) });
  });
};

export const setWidgetDesignTabs = (
  widgetDesignTabsBuilder: WriteOnlyBuilder<WidgetDesignTabsBuilder>,
  widgetDesignTabsArray: WidgetDesignTabsArray,
  t: TFunction
) => {
  widgetDesignTabsArray.forEach(({ label, roles, dependents }) => {
    addTab(widgetDesignTabsBuilder, t(label), roles, dependents);
  });
};

export const disableWidgetComponentSelection = (builder: WidgetBuilder, componentId: string) => {
  builder.configureConnectedComponents(getRole(componentId), (titleAndDescriptionBuilder) => {
    titleAndDescriptionBuilder
      .behavior()
      .set({ closed: { hideFromHierarchy: true, selectable: false }, preventHide: false });
  });
};

const configureTextMainActionSettingsGfpp = ({
  widgetBuilder,
  editorSDK,
  t,
  role,
  label,
}: {
  widgetBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  t: TFunction;
  role: string;
  label: string;
}) => {
  widgetBuilder.configureConnectedComponents(role, (componentBuilder) => {
    componentBuilder.set({
      displayName: label,
    });
    componentBuilder.gfpp().set('mainAction1', {
      label: t('menu.text-element.design-text-button'),
      onClick: (event) => {
        event.preventDefault();
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: event.detail.componentRef,
        });
      },
    });
    componentBuilder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
  });
};

export const configureWidgetTextDesignGfpp = ({
  widgetBuilder,
  editorSDK,
  t,
  textComponents,
}: {
  widgetBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  t: TFunction;
  textComponents: { id: string; label: string }[];
}) => {
  for (const component of textComponents) {
    configureTextMainActionSettingsGfpp({
      widgetBuilder,
      editorSDK,
      t,
      role: getRole(component.id),
      label: component.label,
    });
  }
};

export const setMenusMobileGfpp = (appManifest: AppManifest) => {
  const menusStageDataDefault = appManifest.controllersStageData![MENUS_CONTROLLER_WIDGET_ID].default;
  const mobileMenusGfpp = (menusStageDataDefault.gfpp as GfppConfig).mobile as GfppMobileConfig;
  // @ts-expect-error
  mobileMenusGfpp.widgetDesign = menusStageDataDefault.gfpp.desktop.widgetDesign;
};

export const setMenusPresetsData = (appManifest: AppManifest, isMobile: boolean, isStudio: boolean, t: TFunction) => {
  const menusStageDataDefault = appManifest.controllersStageData![MENUS_CONTROLLER_WIDGET_ID].default;
  const desktopMenusGfpp = (menusStageDataDefault.gfpp as GfppConfig).desktop as GfppDesktopConfig;
  const mobileMenusGfpp = (menusStageDataDefault.gfpp as GfppConfig).mobile as GfppMobileConfig;
  const helpId = MENU_PRESETS_PANEL_ARTICLE_ID;

  if (isStudio) {
    desktopMenusGfpp.widgetPresets = {
      presets: isMobile ? getMenuWidgetPresetsMobile(t) : getMenuWidgetPresets(t),
      helpId,
    };
  } else {
    desktopMenusGfpp.widgetPresets = { presets: getMenuWidgetPresets(t), helpId };
    // @ts-expect-error
    mobileMenusGfpp.widgetPresets = { presets: getMenuWidgetPresetsMobile(t), helpId };
  }
};

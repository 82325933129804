import {
  ONE_COLUMN_PRESET_IMAGE,
  TWO_COLUMN_PRESET_IMAGE,
  THREE_COLUMN_PRESET_IMAGE,
  SBS_PRESET_IMAGE,
  STRIP_COLUMN_PRESET_IMAGE,
  GRID_PRESET_IMAGE,
  MOBILE_CARDS_COLUMN_PRESET_IMAGE,
  MOBILE_CENTER_COLUMN_PRESET_IMAGE,
  MOBILE_LEFT_COLUMN_PRESET_IMAGE,
  MOBILE_STRIP_COLUMN_PRESET_IMAGE,
} from 'root/assets/images';
import type { WidgetPreset } from '@wix/platform-editor-sdk';
import {
  CARD_MOBILE_PRESET_ID,
  GRID_PRESET_ID,
  LIST_1_COLUMN_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_PRESET_ID,
  LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID,
  LIST_2_COLUMN_PRESET_ID,
  LIST_3_COLUMN_PRESET_ID,
  MENU_WIDGET_PRESET_IDS,
  MENU_WIDGET_PRESET_IDS_MOBILE,
  SIDE_BY_SIDE_1_COLUMN_PRESET_ID,
} from './consts';
import type { TFunction } from '@wix/yoshi-flow-editor';

export const MENU_PRESET_ID_TO_PRESET_NAME_MAP = {
  [LIST_1_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS.listOneColumn,
  [LIST_2_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS.listTwoColumn,
  [LIST_3_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS.listThreeColumn,
  [LIST_1_COLUMN_WITH_IMAGE_PRESET_ID]: MENU_WIDGET_PRESET_IDS.listOneColumnWithImage,
  [SIDE_BY_SIDE_1_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS.sideBySideOneColumn,
  [GRID_PRESET_ID]: MENU_WIDGET_PRESET_IDS.grid,
};

export const MENU_PRESET_ID_TO_PRESET_NAME_MOBILE_MAP = {
  [CARD_MOBILE_PRESET_ID]: MENU_WIDGET_PRESET_IDS_MOBILE.cards,
  [LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID]: MENU_WIDGET_PRESET_IDS_MOBILE.list2And3Column,
  [LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID]: MENU_WIDGET_PRESET_IDS_MOBILE.listOneColumnWithImage,
  [LIST_1_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS_MOBILE.center,
};

export const getMenuWidgetPresets = (t: TFunction): WidgetPreset[] => [
  {
    id: LIST_1_COLUMN_PRESET_ID,
    src: ONE_COLUMN_PRESET_IMAGE,
    name: t('layout-panel.center-layout.label'),
  },
  {
    id: SIDE_BY_SIDE_1_COLUMN_PRESET_ID,
    src: SBS_PRESET_IMAGE,
    name: t('layout-panel.sbs-layout.label'),
  },
  {
    id: LIST_2_COLUMN_PRESET_ID,
    src: TWO_COLUMN_PRESET_IMAGE,
    name: t('layout-panel.two-columns-layout.label'),
  },
  {
    id: LIST_1_COLUMN_WITH_IMAGE_PRESET_ID,
    src: STRIP_COLUMN_PRESET_IMAGE,
    name: t('layout-panel.strip-layout.label'),
  },
  {
    id: LIST_3_COLUMN_PRESET_ID,
    src: THREE_COLUMN_PRESET_IMAGE,
    name: t('layout-panel.three-columns-layout.label'),
  },
  {
    id: GRID_PRESET_ID,
    src: GRID_PRESET_IMAGE,
    name: t('layout-panel.grid-layout.label'),
  },
];

export const getMenuWidgetPresetsMobile = (t: TFunction): WidgetPreset[] => [
  {
    id: LIST_1_COLUMN_PRESET_ID,
    src: MOBILE_CENTER_COLUMN_PRESET_IMAGE,
    name: t('layout-panel.center-layout.label'),
  },
  {
    id: LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID,
    src: MOBILE_LEFT_COLUMN_PRESET_IMAGE,
    name: t('layout-panel.mobile.left-layout.label'),
  },
  {
    id: CARD_MOBILE_PRESET_ID,
    src: MOBILE_CARDS_COLUMN_PRESET_IMAGE,
    name: t('layout-panel.mobile.cards-layout.label'),
  },
  {
    id: LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID,
    src: MOBILE_STRIP_COLUMN_PRESET_IMAGE,
    name: t('layout-panel.strip-layout.label'),
  },
];
